import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import { ROUTES } from "./consts/Routes";

const App = () => {
  return (
    <div className='BaseApp'>
      <Router>
        <Header /> {/* Navigation bar and site-wide header */}
        <Routes>
          {ROUTES.map((route) => <Route key={route.path} path={route.path} element={route.element} />)}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
