import React, { useState, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import "../styles/Header.css";

// Lazy load the icons
// const ArticleIcon = lazy(() => import('@mui/icons-material/Article'));
// const HelpIcon = lazy(() => import('@mui/icons-material/Help'));
// const SchoolIcon = lazy(() => import('@mui/icons-material/School'));
// const PrivacyTipIcon = lazy(() => import('@mui/icons-material/PrivacyTip'));
// const GavelIcon = lazy(() => import('@mui/icons-material/Gavel'));

const Header = () => {
  const [isResourcesDropdownOpen, setIsResourcesDropdownOpen] = useState(false);

  const toggleResourcesDropdown = (e) => {
    setIsResourcesDropdownOpen(!isResourcesDropdownOpen);
    e.currentTarget.closest('.dropdown-container').classList.toggle('active');
  };

  return (
    <header>
      <div className="header-content">
        <div className="header-info">
          <img className="logo" src="https://storage.googleapis.com/images-website/website/logo.jpeg" alt="Inner Circle Survey Logo" />
          <Link to="/" className="title">Inner Circle Survey</Link>
        </div>
        <div className="header-right">
          <nav className="NavbarLinksContainer">
            <ul>
              <li className="dropdown-container">
                <div className="dropdown" onClick={toggleResourcesDropdown}>
                  <span className="arrow">&#9660;</span>Resources<span className="arrow">&#9660;</span>                    
                </div>
                {isResourcesDropdownOpen && (
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/blog">                        
                        Articles
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq">                        
                        FAQs
                      </Link>
                    </li>
                    <li>
                      <Link to="/howitworks">                        
                        How To
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy">                        
                        Privacy
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">                        
                        Terms
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
